.product-box .product-money span{
    margin: 0 3px;
}
.side-shop .side-post:last-child, 
.side-blog .side-post:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}
.side-shop .side-post .entry-tye span.text-price{
    text-decoration: line-through;
    color: gray;
    margin-left: 6px;
}
.shop-detail-image .product-label{
    left: auto;
    right: 0;
}
.shop-detail-contents .price .price-tag+span{
    text-decoration: line-through;
    color: gray;
}
.side-shop .thumb-img a , .side-blog .thumb-img a{
    width: 80px;
    height: 80px;
    display: block
}
.checkout-product .product-img img,
.review-box .review-user .review-user-img img,
.side-shop .thumb-img img, .side-blog .thumb-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.review-box .review-user .review-user-img img{
    width: 40px;
    height: 40px;
}
.checkout-product .product-img img{
    width: 100px;
    height: 100px;
}
.doctor-wrapper{
    min-height: 300px;
    height: calc(100% - 30px);
}
.doctor-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.delivery-slider .irs--round .irs-handle>i:first-child,
.delivery-slider .irs--round .irs-from,
.delivery-slider .irs--round .irs-to {
    background-color: #334c7d!important;
}

.delivery-slider .irs--round .irs-bar {
    background-color: #334c7d!important;
    height: 2px!important;
}

.delivery-slider .irs--round .irs-from:before,
.delivery-slider .irs--round .irs-to:before {
    border-top-color: #334c7d!important;
}

.delivery-slider .irs--round .irs-line {
    height: 2px!important;
}

.delivery-slider .irs--round .irs-handle {
    top: 30px!important;
    width: 15px!important;
    height: 15px!important;
    border-radius: 50%!important;
    background-color: #334c7d!important;
	border: none!important;
	box-shadow: none!important;
}
.bg-theme-primary.block_newsletter p,
.bg-theme-primary.block_newsletter .header-title{
    color: #fff;
}
.map-container .map-main {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	overflow: hidden;
}


.map-container .map-main {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	overflow: hidden;
}
.leaflet-popup-content-wrapper{
    background: transparent;
    box-shadow: none;
}
.leaflet-popup-content{
    margin: 0;
    width: 450px!important;
}
.leaflet-container a.leaflet-popup-close-button{
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 20px;
    right: 20px;
    color: #fff;
    border-radius: 100%;
    z-index: 20;
    box-shadow: 0px 0px 0px 5px rgb(255 255 255 / 40%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: #4d93e9;
    font-size: 18px;
    font-style: normal;
    padding: 0;
}
.listing-box .listing-wrapper{
    width:100%
}
.bg-theme-primary .header-title,
.bg-theme-primary .doctor-content p,
.bg-theme-primary .doctor-info h6,
.bg-theme-primary .countdown-box .time-box span h6,
.bg-theme-primary .dealofday-content .sub-title,
.bg-theme-primary .dealofday-content p,
.bg-theme-primary .dealofday-content h5,
.bg-theme-primary .dealofday-content h4{
    color: #fff;
}
.bg-theme-primary .dealofday-content .btn{
    background: transparent;
    border: 1px solid #fff;
}
.bg-theme-primary .countdown-box .time-box {
    background-color: #4d93e9;
    color: #fff;
}
.bg-theme-primary .doctor-content p span{
    color: #4d93e9;
}
@media(max-width:992px){
	.main-navigation{
		overflow: auto;
	}
}
@media (max-width:576px){
    .subscribe-content{
        height: auto;
        background: transparent;
    }
    #subscribe-input{
        float: none;
        height: 70px;
        border-radius: 0;
        border: 1px #fff solid;
        min-width: auto;
    }
    #subscribe-input::placeholder{
        color: #fff;
    }
    #subscribe-input.outline-case{
        border-right: 1px #fff solid;
    }
    .subscribe-content button.button{
        position: relative;
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 0;
    }
    .subscribe-content button.button span{
        position: relative;
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 0;
        margin: 10px 0 0 0;
    }
}